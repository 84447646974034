.CardsMain {
    display: flex;
    justify-content: center;
}

.CardSingle {
    width: 300px
}

.UpperBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: black;
    text-align: center;
    height: 3rem;
    border-radius: 1rem 1rem 0rem 0rem;
}

.CardLogo{
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
}

.SpacerDiv {
    width: 3rem;
    height: 3rem;
}

.AuthorName {
    margin-bottom: 0px;
    margin-left: -5.5rem;
    Color: #ff9900;
}

.CardBg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #4D4D4D;
    padding: 2%;
}

.CardInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: lightgray;
    border-radius: 20px;
    width: 15rem;
    height: 23rem;
    overflow: hidden;
}

.Thumbnaildiv {
}

.Thumbnailimage {
    height: 9rem;
    width: 9rem;
    padding-top: 0.5rem;
}

.ContentTitleDiv {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    text-align: center;
}

.ContentTitleText{
    margin: 0px;
    color: black;
}

.ContentDescription {
    text-align: center;
    width: 12rem;
}

.ContentParagraph{
    padding-bottom: 0.2rem;
    font-size: 8px;
    margin: 0px;
    color: black !important;
}

.CardConsumeButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
    margin-top: -6.9rem;
}

.button {
    position: relative;
    overflow: hidden;
    transition: transform 2s;
}

.ConetentBars {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ListenUnderlying, .ReadUnderlying, .WatchUnderlying {
    margin: 0.2rem;
}

.FiatBar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 1.9rem;
    width: 12rem;
    background-color: green;
    border-radius: 25px;
    padding-right: 5%;
}

.FiatItemsDiv{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 4rem;
    height: 2rem;
    margin-top: -0.5rem;
}

.BitcoinBar {
    display: flex;
    justify-content: space-evenly;
    height: 1.9rem;
    width: 6rem;
    margin-top: -1.9rem;
    background-color: orange;
    border-radius: 25px;
}

.BitcoinContentItems, .FiatItems {
    height: 1.2rem;
    width: 1.2rem;
    padding: 2px;
    cursor: pointer;
}

.FiatItems, .BitcoinContentItems {
    cursor: pointer;
}

.ReadButton, .ListenButton, .WatchButton {
    background-color: black;
    color: white;
    border-radius: 25px;
    margin: 0.155rem;
    width: 12rem;
    height: 2rem;
}

.ReadButton::after, .ListenButton::after, .WatchButton::after {
    transition: 3s;
}

.LowerBar {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: black;
    text-align: center;
    height: 3rem;
    border-radius: 0rem 0rem 1rem 1rem;
}


.DonateButton, .ContactButton {
    background-color: #4D4D4D;
    color: #ff9900;
    border-radius: 25px;
    width: 7rem;
    height: 2rem;
    margin: 1rem;
}